import { FormlyFieldConfig } from '@ngx-formly/core';
import { format } from 'date-fns';

export function dateInputFormatter(field: FormlyFieldConfig) {
  try {
    if (!!field.formControl.value) {
      // Cast the value to a date
      const date = new Date(field.formControl.value);
      // Format the date to a input-supported format
      const isoDate = format(date, 'yyyy-MM-dd');
      // Set the value to be the correctly formatted string
      field.formControl.setValue(isoDate);
    }
  } catch (e) {
    // If failed, log error and don't change anything
    console.error(`Couldn't convert value to date: ${field.formControl.value}`);
  }
}
