import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import {
  ActionTypes,
  ClaimIdentifierTypes,
  DocumentFilterTypes,
  DocumentIdentifierTypes,
  EntitySearchMethods,
  EntityTypes,
  IDocumentBase,
  IEntitySearchRequest,
  CounselFilterTypes,
  IEntityBase,
  IParty,
  CounselTypes,
  SearchableEntityTypes,
  IEntitySearchResponseMatcher,
} from '@solomonicuk/core-sdk';
import { combineLatest, merge, of, ReplaySubject, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { SelectOption } from 'src/app/core/types/select-option.interface';
import { environment } from 'src/environments/environment';
import { Counsel } from './../../../data/types/counsel.interface';
import { FilterConfig } from './../../types/filter-config.interface';

const MINIMUM_SEARCH_LENGTH = 2;
const RESULT_COUNT = 3;

interface EntitySearchConfig<EntityType, EntityFilterTypes> {
  isIndexedEntity: false;
  payload: {
    url: string;
    toLabel: (entity: EntityType) => string;
    toSearch: (val: string) => Array<FilterConfig<EntityFilterTypes>>;
    toResults: (response: any) => any[];
  };
}

type AnyEntitySearchConfig = IndexedEntitySearchConfig | EntitySearchConfig<any, any>;

interface IndexedEntitySearchConfig {
  isIndexedEntity: true;
  payload: {
    entityType: EntityTypes;
  };
}

// use in "toResults" field if results are the top level
const resultsAtTopLevel = (res: any[]): any[] => res;

// use in "toResults" field when results are in their own property
const resultsNested = (res: { results: any[] }): any[] => res.results;

export const toAlias = (entity: IEntityBase, search: string): string => {
  // use legacy case number as alias if entity is a claim
  const claimEntity: any = entity;
  if (claimEntity.type === ActionTypes.Claim) {
    const legacyCaseNumber = claimEntity.identifiers.find(
      identifier => identifier.type === ClaimIdentifierTypes.LegacyClaimNumber,
    );
    if (!legacyCaseNumber) {
      return;
    }
    entity.aliases = [{ item: legacyCaseNumber.value }];
  }

  if (!entity.aliases || !entity.aliases.length) {
    return;
  }

  const searchVal = search.toLowerCase();

  const normalisedAliases = entity.aliases.reduce((memo, singleAlias) => {
    return [...memo, singleAlias.item.toLowerCase()];
  }, []);

  const index = normalisedAliases.findIndex(element => element.includes(searchVal));

  const val = entity.aliases[index];

  if (!val) {
    return undefined;
  }

  return val.item;
};

// TODO: Merge this with the entity configs
const entitySearchConfigs = new Map<SearchableEntityTypes, AnyEntitySearchConfig>([
  [
    // TODO: this needs to be switched out when the `SearchableEntityTypes` / `EntityTypes` consolidation happens
    (EntityTypes.Claim as unknown) as SearchableEntityTypes,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Claim,
      },
    },
  ],
  [
    SearchableEntityTypes.Barrister,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Barrister,
      },
    },
  ],
  [
    SearchableEntityTypes.Expert,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Expert,
      },
    },
  ],
  [
    SearchableEntityTypes.Judge,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Judge,
      },
    },
  ],
  [
    SearchableEntityTypes.LawFirm,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.LawFirm,
      },
    },
  ],
  [
    SearchableEntityTypes.Party,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Party,
      },
    },
  ],
  [
    SearchableEntityTypes.Topic,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Topic,
      },
    },
  ],
  [
    SearchableEntityTypes.Chambers,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        // TODO: add chambers to entity types
        entityType: 'CHAMBERS' as EntityTypes,
      },
    },
  ],
  [
    SearchableEntityTypes.InsolvencyPractitioner,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.InsolvencyPractitioner,
      },
    },
  ],
  [
    SearchableEntityTypes.HearingType,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.HearingType,
      },
    },
  ],
  [
    SearchableEntityTypes.Textbook,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Textbook,
      },
    },
  ],
  [
    SearchableEntityTypes.Counsel,
    <EntitySearchConfig<Counsel, CounselFilterTypes>>{
      isIndexedEntity: false,
      payload: {
        url: `${environment.dataServiceUrlSls}/v2/entities/counsel`,
        toResults: resultsAtTopLevel,
        toLabel: (counsel: Counsel) => `${counsel.name}`,
        toSearch: (val: string) => [
          {
            method: CounselFilterTypes.Type,
            payload: {
              types: [
                CounselTypes.InsolvencyPractitioner,
                CounselTypes.JuniorBarrister,
                CounselTypes.SeniorBarrister,
                CounselTypes.Solicitor,
                CounselTypes.SolicitorAdvocate,
                CounselTypes.LawFirm,
              ],
            },
          },
          {
            method: CounselFilterTypes.ByName,
            payload: {
              name: val,
            },
          },
        ],
      },
    },
  ],
  [
    SearchableEntityTypes.Document,
    <EntitySearchConfig<IDocumentBase, DocumentFilterTypes>>{
      isIndexedEntity: false,
      payload: {
        url: `${environment.dataServiceUrlSls}/v2/documents`,
        toLabel: (document: IDocumentBase) => {
          const documentCitation = document.identifiers.find(
            i => i.type === DocumentIdentifierTypes.Citation,
          );
          return documentCitation.value || 'Unknown Document Citation';
        },
        toSearch: (val: string) => [
          {
            method: DocumentFilterTypes.IdentifierSearch,
            payload: {
              types: [DocumentIdentifierTypes.Citation],
              value: val,
            },
          },
        ],
        toResults: response => response.results,
        alternateURL: `${environment.dataServiceUrlSls}`,
      },
    },
  ],
  [
    SearchableEntityTypes.Application,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Application,
      },
    },
  ],
  [
    SearchableEntityTypes.FactualSubjectMatter,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.FactualSubjectMatter,
      },
    },
  ],
  [
    SearchableEntityTypes.LegalSubjectMatter,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.LegalSubjectMatter,
      },
    },
  ],
  [
    SearchableEntityTypes.Remedy,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Remedy,
      },
    },
  ],
  [
    SearchableEntityTypes.Injunction,
    <IndexedEntitySearchConfig>{
      isIndexedEntity: true,
      payload: {
        entityType: EntityTypes.Injunction,
      },
    },
  ],
]);

@Component({
  selector: 'app-formly-field-entity-search',
  templateUrl: './formly-field-entity-search.component.html',
  styleUrls: ['./formly-field-entity-search.component.scss'],
})
export class FormlyFieldEntitySearchComponent extends FieldType implements OnInit {
  public selectOptionSubject = new Subject<SelectOption<IParty>>();

  public dataSubject = new ReplaySubject<{ name: string; id: string; taggedTo: string }>();
  public data$ = this.dataSubject.asObservable().pipe(shareReplay(1));

  private formSubject = new ReplaySubject<FormGroup>();
  public form$ = this.formSubject.asObservable().pipe(shareReplay(1));

  public fieldSubject = new ReplaySubject<FormlyFieldConfig>();
  public field$ = this.fieldSubject.asObservable().pipe(shareReplay(1));

  public initialFormValue$ = this.form$.pipe(
    map(form => form.value),
    shareReplay(1),
  );

  public formChanges$ = this.form$.pipe(
    switchMap(form => form.valueChanges),
    shareReplay(1),
  );

  public isTaggedEntity$ = merge(this.initialFormValue$, this.formChanges$).pipe(
    map(entity => !!entity.id),
  );

  public entityTag$ = merge(this.initialFormValue$, this.formChanges$).pipe(
    map(entity => entity.taggedTo),
  );

  public entityType$ = combineLatest([
    merge(this.initialFormValue$, this.formChanges$),
    this.field$,
  ]).pipe(map(([val, field]) => val.entityType || field.fieldGroup[0].defaultValue));

  public showEntityTag$ = this.entityType$.pipe(
    map(
      entityType =>
        ![SearchableEntityTypes.Claims, SearchableEntityTypes.Document].includes(entityType),
    ),
  );

  public searchField$ = this.form$.pipe(
    map(form => {
      const control = new FormControl(form.value.name);
      form.disabled ? control.disable() : control.enable();
      if (!control.disabled && !form.value.id) {
        this.selectOptionSubject.next({ label: null, value: null });
      }
      return control;
    }),
    shareReplay(1),
  );

  public searchValue$ = this.searchField$.pipe(
    switchMap(control =>
      control.valueChanges.pipe(
        tap(() => {
          if (!control.disabled) {
            this.selectOptionSubject.next({ label: null, value: null });
          }
        }),
        startWith(control.value),
      ),
    ),
  );

  public update$ = combineLatest([this.data$, this.searchField$]).pipe(
    tap(([data, field]) =>
      field.patchValue(data.name, {
        onlySelf: true,
        emitEvent: false,
      }),
    ),
  );

  public nameChanges$ = this.formChanges$.pipe(
    filter(model => model.name !== undefined),
    withLatestFrom(this.searchField$),
    map(([model, searchField]) =>
      searchField.patchValue(model.name, { onlySelf: true, emitEvent: false }),
    ),
  );

  public optionSelection$ = this.selectOptionSubject.pipe(shareReplay(1));

  public select$ = combineLatest([this.optionSelection$, this.form$]).pipe(
    filter(([option]) => option.label !== null || option.value !== null),
    tap(([option, form]) =>
      form.patchValue({
        id: option.value,
        taggedTo: undefined,
        name: option.label,
      }),
    ),
    tap(([option]) =>
      this.dataSubject.next({
        id: option.value,
        taggedTo: undefined,
        name: option.label,
      }),
    ),
  );

  public options$ = combineLatest([
    this.searchValue$.pipe(filter(val => !!val && val.length >= MINIMUM_SEARCH_LENGTH)),
    this.entityType$,
  ]).pipe(
    debounceTime(150),
    switchMap(([val, entityType]) => {
      // Get the config for the provided entity type
      const searchConfig = entitySearchConfigs.get(entityType);
      // Could be more complex than an IF toggle but we are planning on completely removing the other method
      // So this will do for now!
      if (searchConfig) {
        // Do the search
        if (searchConfig.isIndexedEntity) {
          const request: IEntitySearchRequest = {
            types: [entityType],
            method: {
              type: EntitySearchMethods.AppSearch,
              payload: {
                term: val,
                normaliseTerm: false,
              },
            },
          };
          return this.http
            .post(`${environment.searchServiceUrlSls}/v1/entity-search`, request)
            .pipe(
              map((response: IEntitySearchResponseMatcher) => {
                return response.results.map(({ entity, score }) => ({
                  label: entity.name,
                  score,
                  value: entity.solomonicId,
                  payload: {
                    primaryName: entity.primaryName,
                    entity: {
                      id: entity.solomonicId,
                      name: entity.name,
                      entityType: entity.type,
                    },
                  },
                }));
              }),
              catchError(err => of(console.error(err))),
            );
        } else {
          const { url, toSearch, toResults, toLabel } = (searchConfig as EntitySearchConfig<
            any,
            any
          >).payload;
          return this.http
            .post<IParty[]>(`${url}/search?page=1&limit=${RESULT_COUNT}`, {
              filters: toSearch(val),
            })
            .pipe(
              map(toResults),
              map(entities =>
                entities
                  .filter((entity, index) => index <= RESULT_COUNT - 1)
                  .map(entity => {
                    const primaryName = toLabel ? toLabel(entity) : entity.name;
                    const alias = toAlias(entity, val);

                    if (!alias || primaryName.toLowerCase().includes(val.toLowerCase())) {
                      return {
                        label: primaryName,
                        value: entity.id,
                        payload: { entity },
                      };
                    } else {
                      return {
                        label: alias,
                        value: entity.id,
                        payload: { entity, primaryName },
                      };
                    }
                  }),
              ),
              catchError(err => of(console.error(err))),
            );
        }
      }
      return of([]);
    }),
  );

  public showEntitySelection$ = this.optionSelection$.pipe(map(option => !option.label));

  public handleOptionSelection(option: SelectOption<IParty>): void {
    this.selectOptionSubject.next(option);
  }

  public handleAddNew(val: string): void {
    const trimmedVal = val.trim();
    this.selectOptionSubject.next({ value: null, label: trimmedVal });
  }

  ngOnInit(): void {
    // TODO: Necessary to pass the spec but this should need a try ... catch
    try {
      if (this.form) {
        this.formSubject.next(this.form);
      }
      if (this.field) {
        this.fieldSubject.next(this.field);
      }
    } catch (e) {}
  }

  constructor(private http: HttpClient) {
    super();
  }
}
