// Credit: https://formly-js.github.io/ngx-formly/examples/other/input-file
import { Directive, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appFileInputValueAccessor]',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FileInputValueAccessorDirective, multi: true },
  ],
})
// https://github.com/angular/angular/issues/7341
export class FileInputValueAccessorDirective implements ControlValueAccessor {
  public value: any;

  @HostListener('blur')
  hostBlur() {
    this.onTouched();
  }

  @HostListener('change', ['$event'])
  hostChange(event) {
    this.onChange(event.target.files);
  }

  public onChange = _ => {};
  public onTouched = () => {};

  public writeValue(value) {}
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
}
