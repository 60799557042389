import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

import { IUser, UserRoleTypes } from '@solomonicuk/core-sdk';

import { User } from '../../types/user.interface';
import { AuthService } from './../../services/auth.service';

import { isEqual, intersectionWith } from 'lodash';
import { EnvironmentService } from '../../../users/services/environment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  navItems$ = of([
    {
      label: 'Users',
      path: '/users',
      allowedUsers: [UserRoleTypes.Admin],
    },
    {
      label: 'Cases',
      path: '/cases',
      allowedUsers: [UserRoleTypes.Admin],
    },
    {
      label: 'Entities',
      path: '/entities',
      allowedUsers: [UserRoleTypes.Admin, UserRoleTypes.ReviewPublisher],
    },
    {
      label: 'Documents',
      path: '/documents',
      allowedUsers: [UserRoleTypes.Admin],
    },
    {
      label: 'Reviews',
      path: '/reviews',
      allowedUsers: [UserRoleTypes.Admin, UserRoleTypes.ReviewPublisher],
    },
    {
      label: 'Statistics',
      path: '/statistics',
      allowedUsers: [UserRoleTypes.Admin],
    },
  ]);

  user$: Observable<IUser> = from(this.authService.getDecodedToken<IUser>());

  reducedNavItems$ = this.user$.pipe(
    map(i => {
      return i.roles.reduce((prevRoles, currentRole) => {
        return [...prevRoles, currentRole.roleType as UserRoleTypes];
      }, [] as UserRoleTypes[]);
    }),
    withLatestFrom(this.navItems$),
    map(join => {
      const userRoles = join[0];
      const items = join[1];
      return items.filter(i => !!intersectionWith(i.allowedUsers, userRoles, isEqual).length);
    }),
  );

  environmentType = this.envService.getVariables().tag;

  constructor(
    private router: Router,
    private authService: AuthService,
    private envService: EnvironmentService,
  ) {}
}
