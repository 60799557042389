import { Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { difference } from 'lodash-es';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isArray } from 'util';

@Component({
  selector: 'app-formly-field-checkbox-list',
  templateUrl: './formly-field-checkbox-list.component.html',
  styleUrls: ['./formly-field-checkbox-list.component.scss'],
})
export class FormlyFieldCheckboxListComponent extends FieldType {
  @ViewChild('allCheckbox', { static: false })
  checkbox: ElementRef;

  private valueChangeSubject = new Subject<string[]>();

  indeterminate$ = this.valueChangeSubject.asObservable().pipe(
    tap(val => {
      if (this.checkbox) {
        if (val.length) {
          this.checkbox.nativeElement.indeterminate = difference(
            this.allOptionValues,
            val,
          ).length;
        } else {
          this.checkbox.nativeElement.indeterminate = false;
        }
      }
    }),
  );

  // From: https://github.com/ngx-formly/ngx-formly/blob/master/src/bootstrap/src/lib/types/multicheckbox.ts
  public onChange(value: any, checked: boolean): void {
    // Get the current value
    const curVal = isArray(this.formControl.value) ? this.formControl.value : [];
    // Add/Remove option, depending on whether the checkbox is checked or no
    const newVal = checked ? [...curVal, value] : [...curVal.filter(o => o !== value)];
    this.formControl.patchValue(newVal);
    // Indicate that Angular should update with changes
    this.formControl.markAsTouched();
    this.valueChangeSubject.next(newVal);
  }

  public get allChecked(): boolean {
    if (isArray(this.formControl.value)) {
      return difference(this.allOptionValues, this.formControl.value).length === 0;
    }
    return false;
  }

  private get allOptionValues(): string[] {
    return (this.to.options as any[]).map(option => option.value);
  }

  public toggleAll(): void {
    if (this.allChecked) {
      this.formControl.patchValue([]);
    } else {
      this.formControl.patchValue(this.allOptionValues);
    }
  }
}
