import { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ReplaySubject } from 'rxjs';

interface Props {
  [propName: string]: any;
}

/**
 * See https://github.com/angular/angular/pull/20682
 *
 * It is very useful to be able to access lifecycle events as observables.
 *
 * This class should be removed when this (or similar) is added to Angular,
 * until then we have to use LifecycleProvider to give classes access to
 * lifecycle streams.
 *
 * For now only the onChanges stream is supported, and it emits events as an
 * object rather than a list of SimpleChange objects. The rxjs operator
 * `pairwise` is a better way of accessing a value with its previous value than
 * the mechanism provided by SimpleChange.
 *
 * The onChanges observable is closed during ngOnDestroy, this can be
 * used to detect destruction of the component.
 */
class Lifecycle {
  private onChangesSubject = new ReplaySubject<Props>(1);
  onChanges = this.onChangesSubject.asObservable();

  // IMPORTANT: the rest of the methods in the this class should only be called
  //            by LifecycleProvider and during tests
  __changes(props: Props) {
    this.onChangesSubject.next(props);
  }

  __destroy() {
    this.onChangesSubject.complete();
  }
}

/**
 * This class is a stop-gap method and should eventually be removed. For now it
 * is required to bridge angular's lifecycle methods to `Lifecycle`.
 */
export class LifecycleProvider implements OnChanges, OnDestroy {
  protected lifecycle = new Lifecycle();

  ngOnChanges(changes: SimpleChanges) {
    const changedProps = {};
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        changedProps[propName] = changes[propName].currentValue;
      }
    }
    this.lifecycle.__changes(changedProps);
  }

  ngOnDestroy() {
    this.lifecycle.__destroy();
  }
}
