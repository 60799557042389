import { AuthService } from './../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { map, tap, switchMap, filter, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { userIsReviewPublisher } from 'src/app/shared/helpers/user-is-review-publisher';
import { User } from '../../types/user.interface';
import { IUser, userIsAdmin, userIsAnalyst, userIsQA } from '@solomonicuk/core-sdk';

@Component({
  selector: 'app-store-token',
  templateUrl: './store-token.component.html',
  styleUrls: ['./store-token.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreTokenComponent {
  store$ = this.route.queryParamMap.pipe(
    map(paramMap => paramMap.get('token')),
    tap(token => {
      if (!token) {
        throw new Error(`Token not found`);
      }
    }),
    switchMap((token: string) => from(this.authService.saveToken(token))),
    switchMap(token => from(this.authService.getDecodedToken<IUser>())),
    tap(user => {
      if (userIsAdmin(user)) {
        this.router.navigateByUrl('/');
      } else if (userIsAnalyst(user) || userIsQA(user) || userIsReviewPublisher(user)) {
        this.router.navigateByUrl('/reviews');
      } else {
        throw new Error(`Unauthorized: ${user.email}`);
      }
    }),

    catchError(err => {
      console.error(err);
      return this.router.navigate(['/login']);
    }),
  );

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
  ) {}
}
