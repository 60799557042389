import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ISearchResponse,
  ISubActionEntity,
  parseStringToValidDate,
} from '@solomonicuk/core-sdk';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as localForage from 'localforage';

import { environment } from '../../../environments/environment';
import { SearchFieldService } from '../../shared/services/search-fields.service';
import { addDays, addMinutes } from 'date-fns';
import { delay } from 'lodash-es';

@Injectable()
export class SubActionService {
  searchFieldService: SearchFieldService<any>;

  constructor(private http: HttpClient) {
    this.searchFieldService = new SearchFieldService<ISubActionEntity>(
      http,
      `${environment.dataServiceUrlSls}/v1/sub-actions`,
      [],
    );
    this.loadSubActions();
  }

  private hasLoadedSubject = new Subject<boolean>();
  public hasLoaded$ = this.hasLoadedSubject.asObservable();

  private hashMap: Map<string, string> = new Map();
  private batchSize = 100;

  // how many days before subactions should be reloaded
  private expiryDays = 1;
  // how many seconds to wait before assuming loading has failed
  private maxLoadingSeconds = 30;
  // localForage keys for storage
  private hashMapKey = 'subActionsData';
  private expiryKey = 'subActionsExpiry';
  private isLoadingKey = 'subActionsIsLoading';

  /**
   * populates the Service's hashmap of subactions from either local store or by requesting them from data service
   * @param checkStore default = true, false if you want to forcibly redownload subactions
   */
  public async loadSubActions(checkStore = true) {
    try {
      // check local store for map
      let hashmap =
        // if checkStore not false
        checkStore &&
        // and if the subaction count hasn't changed
        !(await this.subActionCountChanged()) &&
        // try to use the stored map
        (await this.getStoredHashMap());

      // if local store found no hashmap or it was expired or checkstore was false
      if (!hashmap) {
        // set loading state in local store
        await localForage.setItem(this.isLoadingKey, true);
        hashmap = await this.buildHashMap();

        // set loading state in local store
        await localForage.setItem(this.isLoadingKey, false);
        // store built map into local store
        this.storeHashMap(hashmap);
      }

      this.hashMap = hashmap;
      this.hasLoadedSubject.next(true);
    } catch (e) {
      // if something went wrong, make sure to reset this flag to stop anything else from waiting for this
      await localForage.setItem(this.isLoadingKey, false);
      throw e;
    }
  }

  /**
   * creates the hashmap by requesting from data service
   */
  private async buildHashMap(): Promise<Map<string, string> | null> {
    const firstPage = (await this.searchFieldService
      .search({ filters: [] }, { page: 1, limit: this.batchSize })
      .toPromise()) as ISearchResponse<unknown>;

    const pagesArray = [];

    for (let i = 2; i <= firstPage.metadata.pagination.pages; i++) {
      pagesArray.push(i);
    }

    const otherResults = await pagesArray.reduce(async (prevPage, nextPageNumber) => {
      const allResults = await prevPage;
      try {
        const responseForPage = (await this.searchFieldService
          .search({ filters: [] }, { page: nextPageNumber, limit: this.batchSize })
          .toPromise()) as ISearchResponse<unknown>;
        return [...allResults, ...responseForPage.results];
      } catch (e) {}
    }, Promise.resolve([]));

    const allSubactionEntities = [...firstPage.results, ...otherResults];

    const hashmap: Map<string, string> = new Map();
    allSubactionEntities.forEach(subaction => {
      if (subaction.value) {
        hashmap.set(subaction.id, subaction.value);
      }
    });

    return hashmap;
  }

  /**
   * checks local store for an unexpired hashmap
   * @returns null if none found or was expired
   */
  private async getStoredHashMap(): Promise<Map<string, string> | null> {
    // if we're loading this data elsewhere, wait for that to be done
    if (await localForage.getItem<boolean>(this.isLoadingKey)) await this.awaitLoadingMap();
    else {
      const expiry = await localForage.getItem<string>(this.expiryKey);
      // if no expiry seen return null or if the found expiry date is older than now, then return null
      if (!expiry || new Date() > new Date(expiry)) return null;
    }

    const stored = await localForage.getItem<[string, string][] | null>(this.hashMapKey);

    // if stored is null or undefined return that
    if (!stored) return null;

    // else
    return new Map(stored);
  }

  /**
   * detects if there is another process that is loading the subaction data and waits for it to be done
   * @returns
   */
  private async awaitLoadingMap(): Promise<void> {
    for (let i = 0; i < this.maxLoadingSeconds; i++) {
      const isLoading = await localForage.getItem(this.isLoadingKey);

      if (isLoading) {
        await new Promise(resolve => {
          setTimeout(resolve, 1000);
        });
      } else return;
    }
    // if waiting for other process times out, assume something has gone wrong and forcibly build new map using checkStore = false
    await this.loadSubActions(false);
  }

  private storeHashMap(hashMap: Map<string, string>) {
    if (hashMap) {
      localForage.setItem(this.hashMapKey, Array.from(hashMap.entries()));
      localForage.setItem(this.expiryKey, addDays(new Date(), this.expiryDays).toISOString());
    }
  }

  private async subActionCountChanged(): Promise<boolean> {
    const localTotal = ((await localForage.getItem<[string, string][]>(this.hashMapKey)) || [])
      .length;

    const retrievedTotal = ((await this.searchFieldService
      .search({ filters: [] }, { page: 1, limit: 1 })
      .toPromise()) as ISearchResponse<any>).metadata.pagination.results;

    return localTotal !== retrievedTotal;
  }

  public getValueForId(id: string): string {
    return this.hashMap.get(id);
  }

  public getHashMap(): Observable<Map<string, string>> {
    return this.hasLoaded$.pipe(map(hasLoaded => (hasLoaded ? this.hashMap : undefined)));
  }
}
