import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { IUser, userIsAdmin } from '@solomonicuk/core-sdk';
import { User } from 'src/app/core/types/user.interface';

import { ErrorMessages } from '../types/error-messages.enum';
import { AuthService } from './../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IsAdminGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    try {
      const user = await this.authService.getDecodedToken<IUser>();
      if (!userIsAdmin(user)) {
        throw new Error(ErrorMessages.IncorrectUserRole);
      }
      return true;
    } catch (e) {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
