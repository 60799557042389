<ng-container
  *ngIf="
    {
      environmentType: environmentType
    };
    let state
  "
>
  <header
    [ngClass]="[
      state.environmentType === 'UAT'
        ? 'uat-header'
        : state.environmentType === 'DEV'
        ? 'dev-header'
        : state.environmentType === 'LOCAL'
        ? 'local-header'
        : 'prod-header'
    ]"
  >
    <div class="primary-nav">
      <ul class="primary-nav__list" *ngIf="reducedNavItems$ | async; let navItems">
        <li class="primary-nav__list-item" *ngFor="let item of navItems">
          <a
            class="primary-nav__link"
            [routerLink]="item.path"
            routerLinkActive="primary-nav__link--active"
            >{{ item.label }}</a
          >
        </li>
      </ul>
    </div>
    <nav class="user-nav">
      <a class="user-nav__link" href="/login">Logout</a>
    </nav>
  </header>
</ng-container>
