<div class="form__item form__item--option-list">
  <div class="form__item-label" *ngIf="to.label">
    <label>{{to.label}}:</label>
  </div>
  <div class="form__item-control">
    <ul class="option-list">
      <li *ngFor="let option of to.options; let i = index" class="option-list__item">
        <input
          [id]="id + '_' + i"
          type="radio"
          [value]="option.value"
          [formControl]="formControl"
          [formlyAttributes]="field"
          class="option-list__control"
          [name]="id"
        />
        <label
          [for]="id + '_' + i"
        >{{option.label}}</label>
      </li>
    </ul>
  </div>
</div>