<div class="form__item">
  <div class="form__item-label" *ngIf="to.label">
    <label>{{to.label}}:</label>
  </div>
  <div class="form__item-control">
    <div class="repeating-form" *ngIf="field.fieldGroup?.length">
      <div class="repeating-form__item" *ngFor="let arrayField of field.fieldGroup; let i = index;">
        <formly-group
          [field]="arrayField"
          [options]="options"
          [form]="formControl">
          <div class="form__item-info">
            <span class="form__item-info-label">{{field.fieldArray.templateOptions?.iteratedLabel(i + 1)}}</span>
            <button type="button" (click)="remove(i)" class="form__item-info-action link" *ngIf="!to.disabled">{{field.fieldArray.templateOptions?.removeText || 'Remove'}}</button>
          </div>
        </formly-group>
        
      </div>
      <div class="repeating-form__actions" *ngIf="!to.disabled">
        <button class="btn btn-primary" type="button" (click)="add()">+ {{field.fieldArray.templateOptions?.addText || 'Add another'}}</button>
      </div>
    </div>
    <button class="btn btn-primary" type="button" (click)="add()" *ngIf="!field.fieldGroup?.length">+ {{field.fieldArray.templateOptions?.addFirst || 'Add one'}}</button>
  </div>
</div>