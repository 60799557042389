<div
  class="form__item"
  *ngIf="{ valueChangeActions: valueChangeActions$ | async }"
>
  <div
    class="form__item-label"
    *ngIf="to.label"
  >
    <label>{{to.label}}:</label>
  </div>
  <div class="form__item-control">
    <div class="form__item-control-inner">
      <textarea
        class="form__control"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [ngClass]="{ 'form__control--disabled': to.disabled }"
        (change)="onChange()"
      ></textarea>
      <div class="form__item-message form__item-message--error">
        <formly-validation-message [field]="field"></formly-validation-message>
      </div>
    </div>
  </div>
</div>
