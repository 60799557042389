import { AbstractControl } from '@angular/forms';

export const MATCH_VALIDATION_MESSAGE = 'Values must match';

export function matchValidator(control: AbstractControl) {
  // Get the value
  const { value, confirmation } = control.value;
  // Only valid if the values match and are not falsy
  if (!!value && !!confirmation && value === confirmation) {
    return null;
  }
  return { fieldMatch: { message: MATCH_VALIDATION_MESSAGE } };
}
