<div
  class="form__item form__item--option-list"
  *ngIf="
    {
      indeterminate: indeterminate$ | async,
      options: to.options | formlySelectOptions: field | async
    };
    let state
  "
>
  <div class="form__item-label" *ngIf="to.label">
    <label>{{ to.label }}:</label>
  </div>
  <div class="form__item-control" *ngIf="{ showAll: state.options?.length > 1 }; let itemState">
    <ul class="option-list">
      <li class="option-list__item" *ngIf="itemState.showAll">
        <input
          class="option-list__control"
          type="checkbox"
          [id]="id + '_all'"
          [checked]="allChecked"
          (change)="toggleAll()"
          #allCheckbox
        />
        <label [for]="id + '_all'">
          All
        </label>
      </li>
      <li
        *ngFor="let option of state.options; let i = index"
        class="option-list__item"
        [ngClass]="{ 'option-list__item--checkbox': itemState.showAll }"
      >
        <input
          [id]="id + '_' + i"
          type="checkbox"
          [value]="option.value"
          [formlyAttributes]="field"
          class="option-list__control"
          [name]="id"
          (change)="onChange(option.value, $event.target.checked)"
          [disabled]="formControl.disabled"
          [checked]="
            formControl.value &&
            formControl.value.includes &&
            formControl.value.includes(option.value)
          "
        />
        <label [for]="id + '_' + i">{{ option.label }}</label>
      </li>
    </ul>
  </div>
</div>
