<ng-container
  *ngIf="
    {
      submit: submit$ | async,
      email: email$ | async,
      loading: loading$ | async,
      primaryAuthOption: primaryAuthOption$ | async,
      redirectAuthOption: redirectAuthOption$ | async,
      error: error$ | async
    };
    let state
  "
>
  <div class="panel__content" [ngSwitch]="!!state.loading">
    <ng-container *ngSwitchCase="true">
      <p>Loading...</p>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <ng-container [ngSwitch]="!!state.error">
        <ng-container *ngSwitchCase="true">
          <div class="form">
            <p>Sorry, there was an problem logging you in.</p>
            <div class="form__actions">
              <button (click)="handleReset()" class="btn btn--primary">Try again</button>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <ng-container [ngSwitch]="!!state.email">
            <ng-container *ngSwitchCase="false">
              <app-login-form
                [form]="form"
                [isEmail]="true"
                (didSubmit)="handleSubmitPrimary($event)"
              >
              </app-login-form>
            </ng-container>
            <ng-container *ngSwitchCase="true">
              <ng-container [ngSwitch]="state.primaryAuthOption.type">
                <ng-container *ngSwitchCase="authOptionTypes.Password">
                  <app-login-form
                    [form]="form"
                    [isEmail]="false"
                    (didSubmit)="handleSubmitSecondary($event)"
                  >
                  </app-login-form>
                  <p>
                    Logging in as {{ state.email | json }}
                    <span class="small">( <a (click)="handleReset()">cancel</a> )</span>
                  </p>
                </ng-container>
                <ng-container *ngSwitchCase="authOptionTypes.Redirect"></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
