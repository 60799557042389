<div
  class="form__item"
  *ngIf="
    {
      showEntitySelection: showEntitySelection$ | async,
      update: update$ | async,
      select: select$ | async,
      searchValue: searchValue$ | async,
      searchField: searchField$ | async,
      options: options$ | async,
      nameChanges: nameChanges$ | async,
      isTaggedEntity: isTaggedEntity$ | async,
      entityTag: entityTag$ | async,
      showEntityTag: showEntityTag$ | async
    };
    let state
  "
>
  <div class="form__item-label" *ngIf="to.label">
    <label>{{ to.label }}:</label>
  </div>
  <div class="form__item-control entity-search__control">
    <div class="form__item-control-inner">
      <input
        class="form__control"
        [formControl]="state.searchField"
        [type]="to.type"
        [ngClass]="{ 'form__control--disabled': state.searchField.disabled }"
        autocomplete="off"
        [placeholder]="to.placeholder"
        *ngIf="state.searchField"
      />
      <div class="entity-search">
        <ng-container *ngIf="!!state.showEntityTag">
          <div class="entity-search__tagged-label" [ngSwitch]="!!state.isTaggedEntity">
            <ng-container *ngSwitchCase="true">
              ✔ Entity Matched
              <ng-container *ngIf="!!state.entityTag">
                to "{{ state.entityTag }}"
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              ✖ Entity Not Matched
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="state.showEntitySelection">
          <ul class="entity-search__list" *ngIf="state.options?.length > 0">
            <li *ngFor="let option of state.options" class="entity-search__list-item">
              <div class="entity-search__list-item-meta">
                <span
                  >{{ option.label }}
                  <strong
                    *ngIf="option.payload.primaryName"
                    class="entity-search__list-item-type"
                    >({{ option.payload.primaryName }})</strong
                  ></span
                >
                <strong class="entity-search__list-item-type">{{
                  option.payload.entity.type
                }}</strong>
                <strong *ngIf="!!option.score" class="entity-search__list-item-type"
                  >Confidence Score: {{ option.score }}</strong
                >
              </div>
              <button
                type="button"
                class="btn btn--small"
                (click)="handleOptionSelection(option)"
              >
                Select
              </button>
            </li>
          </ul>
          <div
            class="entity-search__actions"
            *ngIf="!!state.searchValue && state.searchValue.length"
          >
            <ng-container [ngSwitch]="!!to.disallowNew">
              <ng-container *ngIf="state.options && !state.options.length">
                <p *ngSwitchCase="true">None found</p>
              </ng-container>
              <button
                *ngSwitchCase="false"
                type="button"
                class="btn btn--primary"
                (click)="handleAddNew(state.searchValue)"
              >
                Add a new {{ to.entityLabel }}
              </button>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
