import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgPipesModule } from 'ngx-pipes';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { CasesMultipageFormComponent } from './../shared/components/cases-multipage-form/cases-multipage-form.component';
import { EntityDisplayComponent } from './../shared/components/entity-display/entity-display.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { EntityListingComponent } from './components/entity-listing/entity-listing.component';
import { FormlyFieldCheckboxListComponent } from './components/formly-field-checkbox-list/formly-field-checkbox-list.component';
import { FormlyFieldEntitySearchComponent } from './components/formly-field-entity-search/formly-field-entity-search.component';
import { FormlyFieldFileComponent } from './components/formly-field-file/formly-field-file.component';
import { FormlyFieldInputComponent } from './components/formly-field-input/formly-field-input.component';
import { FormlyFieldRadioListComponent } from './components/formly-field-radio-list/formly-field-radio-list.component';
import { FormlyFieldSelectComponent } from './components/formly-field-select/formly-field-select.component';
import { FormlyFieldTextareaComponent } from './components/formly-field-textarea/formly-field-textarea.component';
import { FormlyLabelWrapperComponent } from './components/formly-label-wrapper/formly-label-wrapper.component';
import { FormlyRepeatingGroupComponent } from './components/formly-repeating-group/formly-repeating-group.component';
import { PartyListComponent } from './components/party-list/party-list.component';
import { SearchAndFilterComponent } from './components/search-and-filter/search-and-filter.component';
import { FileInputValueAccessorDirective } from './directives/file-input-value-accessor.directive';
import { dateInputFormatter } from './helpers/date-input-formatter';
import { emailValidator, emailValidatorMessage } from './helpers/email-validation';
import { matchValidator } from './helpers/match-validation';
import { FormlyFieldTypes } from './types/formly-field-types.enum';
import { FormlyFieldValidationTypes } from './types/formly-field-validation-types.enum';
import { dateValidator } from './helpers/date-validation';
import { FormlyFieldNumberInputComponent } from './components/formly-field-number-input/formly-field-number-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormlySelectModule,
    FormlyModule.forRoot({
      types: [
        { name: FormlyFieldTypes.Input, component: FormlyFieldInputComponent },
        { name: FormlyFieldTypes.Repeating, component: FormlyRepeatingGroupComponent },
        { name: FormlyFieldTypes.Select, component: FormlyFieldSelectComponent },
        { name: FormlyFieldTypes.File, component: FormlyFieldFileComponent },
        { name: FormlyFieldTypes.Textarea, component: FormlyFieldTextareaComponent },
        { name: FormlyFieldTypes.RadioList, component: FormlyFieldRadioListComponent },
        { name: FormlyFieldTypes.CheckboxList, component: FormlyFieldCheckboxListComponent },
        { name: FormlyFieldTypes.EntitySearch, component: FormlyFieldEntitySearchComponent },
        {
          name: FormlyFieldTypes.Number,
          component: FormlyFieldNumberInputComponent,
          defaultOptions: {
            templateOptions: {
              mask: 'separator',
            },
          },
        },
        {
          name: FormlyFieldTypes.Email,
          extends: FormlyFieldTypes.Input,
          defaultOptions: {
            templateOptions: {
              type: 'email',
            },
            validators: {
              email: emailValidator,
            },
          },
        },
        {
          name: FormlyFieldTypes.Date,
          extends: FormlyFieldTypes.Input,
          defaultOptions: {
            templateOptions: {
              type: 'date',
            },
            hooks: {
              afterContentInit: dateInputFormatter,
            },
          },
        },
      ],
      validators: [
        { name: FormlyFieldValidationTypes.Match, validation: matchValidator },
        {
          name: FormlyFieldValidationTypes.DateRange,
          validation: dateValidator,
        },
      ],
      validationMessages: [
        { name: FormlyFieldValidationTypes.Email, message: emailValidatorMessage },
      ],
      wrappers: [{ name: 'label', component: FormlyLabelWrapperComponent }],
    }),
    NgxMaskModule.forRoot({ thousandSeparator: ',' }),
    RouterModule,
    NgxDatatableModule,
    NgPipesModule,
  ],
  declarations: [
    FormlyFieldInputComponent,
    FormlyFieldNumberInputComponent,
    FormlyRepeatingGroupComponent,
    FormlyFieldSelectComponent,
    ConfirmationComponent,
    FormlyFieldFileComponent,
    FileInputValueAccessorDirective,
    PartyListComponent,
    FormlyFieldTextareaComponent,
    FormlyFieldRadioListComponent,
    FormlyFieldCheckboxListComponent,
    FormlyFieldEntitySearchComponent,
    EntityDisplayComponent,
    EntityListingComponent,
    SearchAndFilterComponent,
    CasesMultipageFormComponent,
    FormlyLabelWrapperComponent,
  ],
  exports: [
    FormlyFieldInputComponent,
    ConfirmationComponent,
    PartyListComponent,
    EntityDisplayComponent,
    EntityListingComponent,
    SearchAndFilterComponent,
    CasesMultipageFormComponent,
  ],
  entryComponents: [EntityDisplayComponent],
})
export class SharedModule {}
