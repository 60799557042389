import { NotificationService } from './../services/notification.service';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { User } from 'src/app/core/types/user.interface';

import { AuthService } from './../services/auth.service';
import { IUser } from '@solomonicuk/core-sdk';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationservice: NotificationService,
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    try {
      const user = await this.authService.getDecodedToken<IUser>();
      if (!user) {
        throw new Error(`Token doesn't container a User`);
      }
      return true;
    } catch (e) {
      this.notificationservice.showError(`Couldn't verify user: ${e.message}`);
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}
