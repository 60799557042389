import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

// tslint:disable-next-line:max-line-length
const EMAIL_VALIDATION_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function emailValidator(control: FormControl): boolean {
  return !control.value || EMAIL_VALIDATION_REGEX.test(control.value);
}

export function emailValidatorMessage(err: Error, field: FormlyFieldConfig) {
  return `"${field.formControl.value}" is not a valid email address`;
}
