import { pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/*
 * RxJs operator to maps an Observable<object> to object[propName], filtering
 * out undefined and null values
 */
export const getPropWhenDefined = propName =>
  pipe(
    map(props => props[propName]),
    filter(propVal => propVal !== undefined),
  );
