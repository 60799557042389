  <div
    class="form__item"
    *ngIf="{ valueChangeActions: valueChangeActions$ | async }"
  >
    <div
      class="form__item-label"
      *ngIf="to.label"
    >
      <label>{{to.label}}:</label>
    </div>
    <div class="form__item-control">
      <div class="form__item-control-inner">
        <input
          class="form__control"
          [type]="to.type"
          [formControl]="formControl"
          [formlyAttributes]="field"
          [ngClass]="{ 'form__control--disabled': to.disabled }"
          (change)="onChange()"
        >
        <div class="form__item-message form__item-message--error">
          <formly-validation-message [field]="field"></formly-validation-message>
        </div>
      </div>
    </div>
  </div>
