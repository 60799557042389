import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-label-wrapper-panel',
  templateUrl: './formly-label-wrapper.component.html',
  styleUrls: ['./formly-label-wrapper.component.scss'],
})
export class FormlyLabelWrapperComponent extends FieldWrapper {
  @ViewChild('fieldComponent', { read: ViewContainerRef, static: true })
  fieldComponent: ViewContainerRef;
}
