<h1>
  Login
</h1>
<div class="panel">
  <form [formGroup]="form" class="form" (ngSubmit)="this.didSubmit.emit(form)">
    <div class="form__feedback">
      <p
        class="form__message form__feedback-message form__message--error"
        *ngIf="result && result?.error"
      >
        Sorry, login failed
      </p>
    </div>
    <div [ngSwitch]="isEmail">
      <ng-container *ngSwitchCase="true">
        <div class="form__item">
          <div class="form__item-label">
            <label for="email">Email Address</label>
          </div>
          <div class="form__item-control">
            <input
              type="email"
              class="form__control form__control--text"
              placeholder="e.g. joe.bloggs@company.com"
              formControlName="email"
              autocomplete="username"
              name="email"
              id="email"
              autofocus
            />
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="false">
        <div class="form__item">
          <div class="form__item-label">
            <label for="password">Password</label>
          </div>
          <div class="form__item-control">
            <input
              type="password"
              class="form__control form__control--password"
              placeholder="Enter your password"
              formControlName="password"
              autocomplete="password"
              name="password"
              id="password"
              autofocus
            />
          </div>
        </div>
      </ng-container>
    </div>
    <div class="form__cta">
      <button type="submit" class="btn">Login</button>
    </div>
  </form>
</div>
