import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { add, parse } from 'date-fns';

export function dateValidator(
  control: FormControl,
  _: FormlyFieldConfig,
  options: { minDate: Date; maxDate: Date },
): ValidationErrors {
  const { value } = control;
  if (!value) {
    return null;
  }
  const selectedDate = parse(value, 'yyyy-MM-dd', new Date());
  const { minDate, maxDate } = options;
  if (selectedDate.toISOString() < minDate.toISOString()) {
    return { dateRange: { message: `Please choose a later date` } };
  } else if (selectedDate.toISOString() > maxDate.toISOString()) {
    return { dateRange: { message: `Please choose an earlier date` } };
  }
  return null;
}
