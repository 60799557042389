
<div class="form__item">
  <div class="form__item-label" *ngIf="to.label">
    <label>{{to.label}}:</label>
  </div>
  <div class="form__item-control">
    <div class="form__control form-select" [ngClass]="{ 'form-select--placeholder': !formControl.value, 'form__control--disabled': to.disabled }">
      <select class="form__control" [formControl]="formControl" [formlyAttributes]="field">
        <option disabled selected [ngValue]="null">{{to.placeholder}}</option>
        <!-- If groups were specified -->
        <ng-container *ngIf="to.groups">
          <optgroup *ngFor="let group of to.groups" [label]="group.label">
            <option *ngFor="let option of group.options" [value]="option.value">{{option.label}}</option>
          </optgroup>
        </ng-container>
        <!-- If no groups were specified -->
        <ng-container *ngIf="!to.groups">
          <option *ngFor="let option of to.options | formlySelectOptions:field | async" [value]="option.value">{{option.label}}</option>
        </ng-container>
      </select>
      <div class="form-select__label">{{selection}}</div>
      <div class="form-select__icon"></div>
    </div>
    <div class="form__item-info">
      <button (click)="clear()" type="button" *ngIf="!to.required" class="form__item-info-action link">Clear</button>
    </div>
  </div>
</div>