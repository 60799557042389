import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent {
  @Input()
  form: FormGroup;
  @Input()
  result: null | HttpErrorResponse;
  @Input()
  isEmail: boolean;
  @Output()
  didSubmit = new EventEmitter<FormGroup>();
}
