import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, of, Observable, from } from 'rxjs';
import {
  catchError,
  switchMap,
  tap,
  filter,
  startWith,
  map,
  withLatestFrom,
} from 'rxjs/operators';

import { User } from '../../types/user.interface';
import { environment } from './../../../../environments/environment';
import { AuthService } from './../../services/auth.service';
import {
  AuthenticationCompletionOptionTypes,
  isOfTypes,
  AuthenticationRequestTypes,
  IAuthenticationResponse,
  AnyAuthenticationCompletionOption,
  IRedirectAuthenticationCompletionOption,
  IUser,
  userIsAdmin,
  userIsAnalyst,
  userIsQA,
} from '@solomonicuk/core-sdk';
import { DOCUMENT } from '@angular/common';
import { userIsReviewPublisher } from 'src/app/shared/helpers/user-is-review-publisher';

const AUTH_COMPLETION_TYPES_PREFS: AuthenticationCompletionOptionTypes[] = [
  AuthenticationCompletionOptionTypes.Redirect,
  AuthenticationCompletionOptionTypes.Password,
];

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  form = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  private loadingSubject = new Subject<boolean>();
  loading$ = this.loadingSubject.asObservable().pipe(startWith(false));

  private errorSubject = new Subject<Error>();
  error$ = this.errorSubject.asObservable();

  private emailSubmissionSubject = new Subject<string>();
  emailSubmission$ = this.emailSubmissionSubject.asObservable();

  email$ = this.emailSubmission$;

  authOptionTypes = AuthenticationCompletionOptionTypes;

  authOptions$ = this.email$.pipe(
    filter(email => !!email),
    tap(() => this.loadingSubject.next(true)),
    switchMap((email: string) =>
      this.authService.getAuthOptionsForEmail(email).pipe(
        tap(() => this.loadingSubject.next(false)),
        map(response => response.options),
        catchError(err => {
          this.errorSubject.next(err);
          return of(err);
        }),
      ),
    ),
  );

  primaryAuthOption$: Observable<AnyAuthenticationCompletionOption | null> = this.authOptions$.pipe(
    map(options => {
      // Not very performant
      const primaryOption = AUTH_COMPLETION_TYPES_PREFS.reduce((pref, type) => {
        // Return if we've already found one
        if (pref) {
          return pref;
        }
        const optionForType = options.find(option => isOfTypes(option.type, [type]));
        // If an option was found for the preference, return it
        if (optionForType) {
          return optionForType;
        }
      }, null);
      // Throw if no option was found
      if (!primaryOption) {
        throw new Error(`No option found`);
      }
      // Return the primary option
      return primaryOption;
    }),
    catchError(err => {
      console.error(`Couldn't find option for use`, err);
      return of(null);
    }),
  );

  // Perform a redirect if returned as an option
  redirectAuthOption$ = this.primaryAuthOption$.pipe(
    filter(
      option =>
        !!option && isOfTypes(option.type, [AuthenticationCompletionOptionTypes.Redirect]),
    ),
    tap(
      (option: IRedirectAuthenticationCompletionOption) =>
        (this.document.location.href = option.payload.url),
    ),
  );

  private passwordSubmissionSubject = new Subject<string>();
  passwordSubmission$ = this.passwordSubmissionSubject.asObservable();

  submit$ = this.passwordSubmission$.pipe(
    withLatestFrom(this.emailSubmission$),
    tap(() => this.loadingSubject.next(true)),
    switchMap(([password, email]: [string, string]) => {
      return this.http
        .post<IAuthenticationResponse>(`${environment.userServiceUrlSls}/v2/auth/token`, {
          type: AuthenticationRequestTypes.Basic,
          payload: {
            email: email.trim(),
            password,
          },
        })
        .pipe(
          switchMap((res: IAuthenticationResponse) =>
            from(this.authService.saveToken(res.token)),
          ),
          switchMap(token => from(this.authService.getDecodedToken<IUser>())),
          tap(user => {
            if (userIsAdmin(user)) {
              this.router.navigateByUrl('/');
            } else if (userIsAnalyst(user) || userIsQA(user) || userIsReviewPublisher(user)) {
              this.router.navigateByUrl('/reviews');
            } else {
              throw new Error(`Unauthorized: ${user.email}`);
            }
          }),
          catchError((err: HttpErrorResponse | Error) => {
            this.errorSubject.next(err);
            return of(err);
          }),
        );
    }),
    tap(() => this.loadingSubject.next(false)),
  );

  constructor(
    private router: Router,
    private http: HttpClient,
    private authService: AuthService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    this.authService.clearToken();
  }

  handleSubmitPrimary(form: FormGroup): void {
    const { email } = form.value || ({} as any);
    this.emailSubmissionSubject.next(email);
  }

  handleSubmitSecondary(form: FormGroup): void {
    const { password } = form.value || ({} as any);
    this.passwordSubmissionSubject.next(password);
  }

  handleReset(): void {
    this.errorSubject.next(undefined);
    this.emailSubmissionSubject.next(undefined);
  }
}
