import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AuthTokenInterceptor } from './core/interceptors/auth-token.interceptor';
import { RequestInterceptor } from './core/interceptors/request.interceptor';
import { SharedModule } from './shared/shared.module';
import { EnvironmentService } from './users/services/environment.service';
import { SubActionService } from './users/services/sub-action.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      easeTime: 100,
      extendedTimeOut: 2000,
    }),
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    EnvironmentService,
    SubActionService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
