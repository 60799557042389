import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Component({
  selector: 'app-formly-field-textarea',
  templateUrl: './formly-field-textarea.component.html',
  styleUrls: ['./formly-field-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormlyFieldTextareaComponent extends FieldType implements OnInit {
  private valueChangeSubject = new Subject<string>();

  public trimmedValue$ = this.valueChangeSubject
    .asObservable()
    .pipe(map(value => value.trim()));

  public valueChangeActions$ = this.trimmedValue$.pipe(
    tap(value => {
      this.formControl.patchValue(value);
      this.formControl.markAsTouched();
    }),
  );

  public onChange(): void {
    this.valueChangeSubject.next(this.formControl.value);
  }

  ngOnInit() {
    //make sure to trim value if editing
    // TODO make this work with observables
    if (this.formControl.value) {
      try {
        this.formControl.patchValue(this.formControl.value.trim());
      } catch (e) {}
    }
  }
}
