import {
  IEntityFilter,
  IEntitySearch,
  IPagination,
  IPaginationMetadata,
  ISearchResponse,
} from '@solomonicuk/core-sdk';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

// TODO: Remove responseToPaginationMetadata and cleanse this service of loose array responses
export class SearchFieldService<CollectionType> {
  constructor(
    private http: HttpClient,
    private url: string,
    private searchFilters: Array<IEntityFilter<unknown>> = [],
  ) {}

  public getAll(
    pagination?: IPagination,
  ): Observable<CollectionType[] | ISearchResponse<CollectionType>> {
    return this.http
      .get<CollectionType[] | ISearchResponse<CollectionType>>(this.url, {
        ...(pagination ? this.getPaginationParams(pagination) : {}),
        observe: 'response',
      })
      .pipe(map(response => this.responseToPaginationMetadata(response)));
  }

  public search(
    query: IEntitySearch<any>,
    pagination?: IPagination,
    searchSuffix: string = 'search',
  ): Observable<CollectionType[] | ISearchResponse<CollectionType>> {
    return this.http
      .post<CollectionType[] | ISearchResponse<CollectionType>>(
        `${this.url}/${searchSuffix}`,
        { filters: [...query.filters, ...this.searchFilters], sort: { updatedAt: -1 } },
        {
          ...(pagination ? this.getPaginationParams(pagination) : {}),
          observe: 'response',
        },
      )
      .pipe(map(response => this.responseToPaginationMetadata(response)));
  }

  private getPaginationParams = (pagination: IPagination) => {
    if (pagination.limit !== undefined && pagination.page !== undefined) {
      return {
        params: new HttpParams()
          .set('limit', pagination.limit.toString())
          .set('page', pagination.page.toString()),
      };
    }
    return;
  };

  private responseToPaginationMetadata = (
    response: HttpResponse<CollectionType[] | ISearchResponse<CollectionType>>,
  ): CollectionType[] | ISearchResponse<CollectionType> => {
    // If pagination header, return newly formed search response
    if (Array.isArray(response.body) && response.headers.has('Pagination-Metadata')) {
      // Form an array of the pagination metadata key value pairs
      const paginationMetadata: Array<{ key: string; value: number }> = response.headers
        .get('Pagination-Metadata')
        .split('; ')
        .map(paginationProperty => ({
          key: paginationProperty.slice(0, paginationProperty.indexOf('=')),
          value: +paginationProperty.slice(paginationProperty.indexOf('=') + 1),
        }));
      // Form new respone object from headers in same format
      return {
        metadata: {
          pagination: paginationMetadata.reduce(
            (metaObj, paginationProperty) => ({
              ...metaObj,
              [paginationProperty.key]: paginationProperty.value,
            }),
            {},
          ) as IPaginationMetadata, // Keys need to match up with IPaginationMetadata, there's no typing on the headers
        },
        results: response.body,
      };
    }
    return response.body;
  };
}
