import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-formly-field-input',
  templateUrl: './formly-field-input.component.html',
  styleUrls: ['./formly-field-input.component.scss'],
})
export class FormlyFieldInputComponent extends FieldType implements OnInit {
  private valueChangeSubject = new Subject<string>();

  public trimmedValue$ = this.valueChangeSubject.asObservable().pipe(
    map(value => {
      if (!isNaN(Number(value))) {
        value.trim();
        return Number(value);
      }
      return value.trim();
    }),
  );

  public valueChangeActions$ = this.trimmedValue$.pipe(
    tap(value => {
      this.formControl.patchValue(value);
      this.formControl.markAsTouched();
    }),
  );

  public onChange(): void {
    this.valueChangeSubject.next(this.formControl.value);
  }

  ngOnInit() {
    // make sure to trim value if editing
    // TODO make this work with observables
    if (isNaN(Number(this.formControl.value))) {
      try {
        this.formControl.patchValue(this.formControl.value.trim());
      } catch (e) {
        throw Error('Could not trim empty value');
      }
    }
  }
}
