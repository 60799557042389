import { Component } from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-repeating-group',
  templateUrl: './formly-repeating-group.component.html',
  styleUrls: ['./formly-repeating-group.component.scss'],
})
export class FormlyRepeatingGroupComponent extends FieldArrayType {
  constructor() {
    super();
  }
}
