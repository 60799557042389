export const environment = {
  production: false,
  tag: 'DEV',
  caseDataUrl: 'https://d3fj8j0lotkz8x.cloudfront.net',
  userServiceUrlSls: 'https://h45m5nheme.execute-api.eu-west-2.amazonaws.com/dev/user-service',
  activityServiceUrl: 'https://gfic3sfcrj.execute-api.eu-west-2.amazonaws.com/dev/user-service',
  dataServiceUrl: 'https://api.dev.solomonic.co.uk/data-service',
  dataServiceUrlSls: 'https://vw8i2g3tz3.execute-api.eu-west-2.amazonaws.com/dev/data-service',
  reviewServiceUrl: 'https://6sfm1ioadf.execute-api.eu-west-2.amazonaws.com/dev/review-service',
  subscriptionServiceUrl:
    'https://ops4lzj0nb.execute-api.eu-west-2.amazonaws.com/dev/subscription-service',
  searchServiceUrlSls:
    'https://htmkef5ove.execute-api.eu-west-2.amazonaws.com/dev/search-service',
  documentOrderServiceUrl:
    'https://cilmg496nl.execute-api.eu-west-2.amazonaws.com/dev/document-order-service',
};
