import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { StoreTokenComponent } from './containers/store-token/store-token.component';
import { LoginComponent } from './containers/login/login.component';
import { PageNotFoundComponent } from './containers/page-not-found/page-not-found.component';
import { AdminLayoutComponent } from './containers/admin-layout/admin-layout.component';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  declarations: [
    HeaderComponent,
    PageNotFoundComponent,
    LoginComponent,
    LoginFormComponent,
    AdminLayoutComponent,
    StoreTokenComponent,
  ],
  exports: [
    HeaderComponent,
    PageNotFoundComponent,
    LoginComponent,
    LoginFormComponent,
    StoreTokenComponent,
  ],
  providers: [AuthService],
})
export class CoreModule {}
