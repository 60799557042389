import { Injectable } from '@angular/core';
import * as jwtDecode from 'jwt-decode';
import * as localForage from 'localforage';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { IAuthenticationStartResponse, IUser } from '@solomonicuk/core-sdk';

import { ErrorMessages } from './../types/error-messages.enum';

import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

const TOKEN_KEY = 'authToken';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  async getToken(): Promise<string> {
    return localForage.getItem<string>(TOKEN_KEY);
  }

  async saveToken(token: string): Promise<string> {
    return localForage.setItem(TOKEN_KEY, token);
  }

  async getDecodedToken<T>(): Promise<T> {
    try {
      const token = await this.getToken();
      if (!token) {
        throw new Error(`No token found`);
      }
      try {
        const decoded = await jwtDecode<T>(token);
        return decoded;
      } catch (e) {
        throw new Error(`Couldn't verify token: ${e.message}`);
      }
    } catch (e) {
      return Promise.reject(`Couldn't decode token: ${e.message}`);
    }
  }

  async clearToken(): Promise<void> {
    return localForage.removeItem(TOKEN_KEY);
  }

  async getUserId(): Promise<string> {
    const user = await this.getDecodedToken<IUser>();
    if (!user || !user.id) {
      throw new Error(ErrorMessages.UserNotFound);
    }
    return user.id;
  }

  getAuthOptionsForEmail(email: string): Observable<IAuthenticationStartResponse> {
    return this.http.post<IAuthenticationStartResponse>(
      `${environment.userServiceUrlSls}/v2/auth/token/options`,
      {
        email,
      },
    );
  }

  validatePassword(password: string, email: string): Observable<null> {
    return (
      this.http
        // a 204 will return body of the response as null
        .post<null>(`${environment.userServiceUrlSls}/v2/validate-password`, {
          password,
          email,
        })
        .pipe(
          catchError(e => {
            console.error(e);
            return throwError(e);
          }),
        )
    );
  }

  constructor(private http: HttpClient) {}
}
