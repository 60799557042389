import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-field-select',
  templateUrl: './formly-field-select.component.html',
  styleUrls: ['./formly-field-select.component.scss'],
})
export class FormlyFieldSelectComponent extends FieldType {
  get selection() {
    if (this.formControl.value) {
      // Get the selected option
      let selectedOption;
      if (this.to.groups) {
        (this.to.groups as any[]).forEach(group => {
          const groupOption = group.options.find(
            option => option.value === this.formControl.value,
          );
          if (groupOption) {
            selectedOption = groupOption;
          }
        });
      } else {
        selectedOption = (this.to.options as Array<{ label: string; value: string }>).find(
          option => option.value === this.formControl.value,
        );
      }
      // Return the value of the selected options' label
      if (selectedOption) {
        return selectedOption.label;
      }
    }
    // If not selected option was found, return the placeholder
    return this.to.placeholder;
  }

  clear(): void {
    this.formControl.patchValue(undefined);
  }
}
