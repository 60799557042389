import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LifecycleProvider } from 'src/app/core/classes/lifecycle-provider.class';

import { getPropWhenDefined } from '../../../core/utils/get-prop-when-defined';

@Component({
  selector: 'app-entity-display',
  templateUrl: './entity-display.component.html',
  styleUrls: ['./entity-display.component.scss'],
})
export class EntityDisplayComponent extends LifecycleProvider {
  @Input()
  entityName: string;

  @Input()
  entity = {};
  entity$ = this.lifecycle.onChanges.pipe(getPropWhenDefined('entity'));

  @Input()
  editing: boolean;
  editing$: Observable<boolean> = this.lifecycle.onChanges.pipe(getPropWhenDefined('editing'));

  @Input()
  fields: FormlyFieldConfig[];
  fields$ = combineLatest([
    this.lifecycle.onChanges.pipe(getPropWhenDefined('fields')),
    this.entity$,
  ]).pipe(map(([fields]) => fields));

  form$ = of(new FormGroup({}));

  @Output()
  cancel = new EventEmitter();

  @Output()
  save = new EventEmitter();
}
