import { Component } from '@angular/core';
import { FormlyFieldInputComponent } from '../formly-field-input/formly-field-input.component';

@Component({
  selector: 'app-formly-field-number-input',
  templateUrl: './formly-field-number-input.component.html',
  styleUrls: ['./formly-field-number-input.component.scss'],
})
export class FormlyFieldNumberInputComponent extends FormlyFieldInputComponent {
  /* Add patterns here to allow for customized masks on inputs
    Defaults include: 
     - 0 = digits (like 0 to 9 numbers)
     - 9 = digits (like 0 to 9 numbers), but optional
     - A = letters (uppercase or lowercase) and digits
     - S = only letters (uppercase or lowercase)
  */
  public patterns = {
    // This pattern allows any character (apart from new line)
    A: { pattern: new RegExp('.') },
  };

  public defaultOptions = {
    templateOptions: {
      // The default mask allows for only number, unlimited times
      mask: '0*',
    },
  };
}
