<div
  class="page-content"
  *ngIf="
    {
      form: form$ | async,
      editing: editing$ | async,
      entity: entity$ | async,
      fields: fields$ | async
    };
    let state
  "
>
  <form
    [formGroup]="state.form"
    (ngSubmit)="save.emit(state.form.value)"
    class="form"
    *ngIf="state.form"
  >
    <h1 class="form__title">
      <strong>{{ state.editing ? 'Edit' : 'Add' }} {{ entityName }}</strong>
    </h1>

    <formly-form
      [form]="state.form"
      [fields]="state.fields"
      [model]="state.entity"
    ></formly-form>

    <div class="page-actions">
      <div class="page-actions__regress">
        <ul class="cta__list">
          <li class="cta__list-item">
            <button class="btn btn--outline btn--wo btn--large" (click)="cancel.emit()">
              Cancel
            </button>
          </li>
        </ul>
      </div>
      <div class="page-actions__progress">
        <ul class="cta__list">
          <li class="cta__list-item">
            <button class="btn btn--wo btn--large" type="submit" [disabled]="!state.form.valid">
              Save
            </button>
          </li>
        </ul>
      </div>
    </div>
  </form>
</div>
