import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

export enum TagTypes {
  PROD = 'PROD',
  UAT = 'UAT',
  DEV = 'DEV',
  LOCAL = 'LOCAL',
}

export interface EnvironmentVariables {
  production: boolean;
  tag: TagTypes;
  caseDataUrl: string;
  userServiceUrlSls: string;
  dataServiceUrl: string;
  dataServiceUrlSls: string;
  subscriptionServiceUrl: string;
  searchServiceUrlSls: string;
}

@Injectable()
export class EnvironmentService {
  getVariables(): EnvironmentVariables {
    return environment as EnvironmentVariables;
  }
  getVariable<T = any>(key: string): T {
    return environment[key];
  }
}
